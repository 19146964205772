// Import and register all your controllers from the importmap under controllers/*

import { application } from "./application"


import FileUploadController from "./file_upload_controller"
application.register("file_upload", FileUploadController)

import HideableController from "./hideable_controller"
application.register("hideable", HideableController)

import QrcodeController from "./qrcode_controller"
application.register("qrcode", QrcodeController)